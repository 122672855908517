import React, { useContext } from 'react';
import SlickSlider from 'react-slick';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import * as styleSlider from 'styles/components/utils/slider.module.scss';
import * as style from 'styles/components/about/page.module.scss';
import useAboutPage from 'data/queries/useAboutPage';
import TextEditorRender from 'components/utils/TextEditorRender';
import { breakpoints } from 'data/utils';
import { LangContext } from 'context/LangContext';
import enMessages from 'i18n/en.json';
import seMessages from 'i18n/se.json';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';
import Banner from 'components/misc/Banner';

import defaultImage from '../images/default-image.jpg';

const MESSAGES = {
    en: enMessages,
    se: seMessages,
};

const About = () => {
    const { lang } = useContext(LangContext);
    const data = useAboutPage();

    // Navigation
    const [currentSection, setCurrentSection] = React.useState(null);
    const refStructure = React.useRef();
    const refHistory = React.useRef();
    const refValues = React.useRef();
    const refWork = React.useRef();
    const refAcademy = React.useRef();
    const refYouth = React.useRef();
    const handleClick = (id) => {
        window.scrollTo(0, id.current.offsetTop - 30);
    };

    const structureBoardImages = [];
    const structureBoardNoImages = [];

    data.clubStructure.forEach((item) => {
        item.staff.forEach((staffMember) => {
          const updatedStaffMember = {
            ...staffMember,
            position: item.position,
          };
          if (updatedStaffMember.position.toLowerCase() === 'ordförande' || updatedStaffMember.position.toLowerCase() === 'vice ordförande' || updatedStaffMember.position.toLowerCase() === 'ordinarie ledamot' || updatedStaffMember.position.toLowerCase() === 'suppleant') {
            structureBoardImages.push(updatedStaffMember);
          } else {
            structureBoardNoImages.push(updatedStaffMember);
          }
        });
    });

    React.useEffect(() => {
        const SECTIONS = [refStructure, refHistory, refValues, refWork, refAcademy];
        const handleScroll = () => {
            // eslint-disable-next-line no-plusplus
            for (let i = SECTIONS.length - 1; i >= 0; i--) {
                if (window.scrollY + 200 > SECTIONS[i].current.offsetTop) {
                    setCurrentSection(SECTIONS[i]);
                    return;
                }
            }
            setCurrentSection(null);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Slider
    let slickSlider = {};
    const [atStart, setAtStart] = React.useState(true);
    const [atEnd, setAtEnd] = React.useState(false);
    const [currentHistorySlide, setCurrentHistorySlide] = React.useState(0);
    const refHistoryYears = React.useRef();
    const sliderSettings = {
        slidesToShow: 1,
        infinite: false,
        arrows: false,
        fade: true,
        beforeChange: (currentSlide, nextSlide) => {
            setCurrentHistorySlide(nextSlide);
            if (window.innerWidth > breakpoints.large) {
                refHistoryYears.current.style.top = `${-85 * nextSlide}px`;
            } else if (window.innerWidth > breakpoints.medium) {
                refHistoryYears.current.style.top = `${-54 * nextSlide}px`;
            } else {
                // eslint-disable-next-line no-shadow
                const currentSlide = refHistoryYears.current.children[nextSlide];
                refHistoryYears.current.scrollTo(
                    currentSlide.offsetLeft -
                        refHistoryYears.current.offsetWidth / 2 +
                        currentSlide.offsetWidth / 2,
                    0,
                );
            }
            setAtStart(nextSlide === 0);
            setAtEnd(nextSlide === data.history.length - 1);
        },
        responsive: [
            {
                breakpoint: breakpoints.medium,
                settings: {
                    dots: true,
                    fade: false,
                },
            },
        ],
    };

    if (!data) {
        return null;
    }

    return (
        <div>
            {/* Banner */}
            <Banner image={data.headerImage} title={data.headerTitle} subtitle={data.headerSubTitle} />

            {/* Internal page nav */}
            <nav className={`${style.nav} bg`}>
                <ul>
                    {[
                        {
                            id: '#01',
                            text: MESSAGES[lang.translationKey].about.structure,
                            ref: refStructure,
                        },
                        {
                            id: '#02',
                            text: MESSAGES[lang.translationKey].about.history,
                            ref: refHistory,
                        },
                        {
                            id: '#03',
                            text: MESSAGES[lang.translationKey].about.values,
                            ref: refValues,
                        },
                        {
                            id: '#04',
                            text: MESSAGES[lang.translationKey].about.youth,
                            ref: refYouth,
                        },
                        {
                            id: '#05',
                            text: MESSAGES[lang.translationKey].about.academy,
                            ref: refAcademy,
                        },
                        {
                            id: '#06',
                            text: MESSAGES[lang.translationKey].about.work,
                            ref: refWork,
                        },
                    ].map((item) => (
                        <li key={item.id}>
                            <a
                                href={item.id}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleClick(item.ref);
                                }}
                                className={`${currentSection === item.ref ? 'active' : ''} text-md`}
                            >
                                {item.text}
                            </a>
                        </li>
                    ))}
                    <li key="#07">
                        <a href="/kontakt" className="text-md">
                            Kontakt
                        </a>
                    </li>
                </ul>
            </nav>
            {/* Structure */}
            <div className={`${style.structure} block-paddings wrapper`} id="01" ref={refStructure}>
                <h2 className="title-xs-mobile title-sm text-semibold">
                    {data.clubStructureTitle}
                </h2>
                <div className={style.structure__board}>

                    <ul className={style.structure__board_list_images}>
                        {structureBoardImages.map((staff, index) => (
                            <li key={index}>
                                <h3 className="text-darker text-md text-semibold">{staff.position}</h3>
                                <div className={style.structure__staff_portrait}>
                                    {staff.image ? (
                                        <ImageGatsby
                                        {...staff.image}
                                        width={800}
                                        alt={staff.staff}
                                    />
                                    ) : (
                                        <img src={defaultImage} alt={staff.staff} />
                                    )}
                                </div>
                                {staff.email ? (
                                    <a href={`mailto:${staff.email}`} className="text-lg">
                                        {staff.staff}
                                    </a>
                                ) : (
                                    <p className="text-lg">{staff.staff}</p>
                                )}
                                {
                                    staff.bio && (
                                        <p>{staff.bio}</p>
                                    )
                                }
                            </li>
                        ))}
                    </ul>

                </div>

                <div className={style.structure__board}>
                    <div className={style.structure__list}>
                        {data.clubStructure.map((item, index) => (
                            (item.position.toLowerCase() !== "ordförande" && item.position.toLowerCase() !== "vice ordförande") && item.position.toLowerCase() !== "ordinarie ledamot" && item.position.toLowerCase() !== "suppleant" && (
                                <div key={index}>
                                    <h3 className="text-darker text-md text-semibold">{item.position}</h3>
                                    <ul>
                                        {item.staff.map((staff, indexMember) => (
                                            <li key={indexMember}>
                                                {staff.email ? (
                                                    <a href={`mailto:${staff.email}`} className="text-lg">
                                                        {staff.staff}
                                                    </a>
                                                ) : (
                                                    <p className="text-lg">{staff.staff}</p>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )
                        ))}
                    </div>
                </div>

            </div>
            <div className={`${style.structure} block-paddings wrapper`}>
                <h2 className="title-xs-mobile title-sm text-semibold">
                    {data.clubStructureSecondaryTitle}
                </h2>
                <div className={style.structure__list}>
                    {data.clubStructureSecondary.map((item, index) => (
                        <div key={index}>
                            <h3 className="text-darker text-md text-semibold">{item.position}</h3>
                            <ul>
                                {item.staff.map((staff, indexMember) => (
                                    <li key={indexMember}>
                                        {staff.email ? (
                                            <a href={`mailto:${staff.email}`} className="text-lg">
                                                {staff.staff}
                                            </a>
                                        ) : (
                                            <p className="text-lg">{staff.staff}</p>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
            {/* Structure Orga */}
            <div className={`${style.orga} bg block-paddings text-center`}>
                <div className={`${style.orga__wrapper} wrapper-s`}>
                    <h2 className="title-sm-mobile title-lg text-semibold">
                        {data.clubStructureOrgaTitle}
                    </h2>
                    <p className="text-md-mobile text-lg">{data.clubStructureOrga}</p>
                    <ImageGatsby
                        {...data.clubStructureOrgaImage}
                        alt={data.clubStructureOrgaTitle}
                    />
                    {data.clubStructureOrgaImageMobile && (
                        <ImageGatsby
                            {...data.clubStructureOrgaImageMobile}
                            alt={data.clubStructureOrgaTitle}
                            className={style.mob}
                        />
                    )}
                </div>
            </div>
            {/* History */}
            <div className={`${style.history} block-paddings bg-reversed`} id="02" ref={refHistory}>
                <div className="wrapper">
                    <div className={`${styleSlider.globalSlider__header} ${style.history__header}`}>
                        <h2 className="title-sm text-semibold">
                            <FormattedMessage id="about.history" />
                        </h2>
                        {
                            // Disable arrows if not enough items
                            !(atStart && atEnd) && (
                                <div className="hidden-mobile">
                                    <button
                                        className={`btn-reset ${styleSlider.globalSlider__prev} ${
                                            style.history__prev
                                        } ${atStart ? 'inactive' : ''}`}
                                        onClick={() => {
                                            if (!atStart) {
                                                slickSlider.slickPrev();
                                            }
                                        }}
                                        type="button"
                                        aria-label={getFormattedMessage('global.prev', lang)}
                                    >
                                        <i className="ico-chevron" aria-hidden="true" />
                                        <span className="visually-hidden">
                                            <FormattedMessage id="global.prev" />
                                        </span>
                                    </button>
                                    <button
                                        className={`btn-reset ${styleSlider.globalSlider__next} ${
                                            style.history__next
                                        } ${atEnd ? 'inactive' : ''}`}
                                        onClick={() => {
                                            if (!atEnd) {
                                                slickSlider.slickNext();
                                            }
                                        }}
                                        type="button"
                                        aria-label={getFormattedMessage('global.next', lang)}
                                    >
                                        <i className="ico-chevron" aria-hidden="true" />
                                        <span className="visually-hidden">
                                            <FormattedMessage id="global.next" />
                                        </span>
                                    </button>
                                </div>
                            )
                        }
                    </div>
                    <div className={style.history__content}>
                        <div className={style.history__years}>
                            <ul ref={refHistoryYears}>
                                {data.history.map((item, index) => (
                                    <li key={index}>
                                        <button
                                            className={`${
                                                currentHistorySlide === index ? 'active' : ''
                                            } btn-reset`}
                                            data-text={item.year}
                                            onClick={() => {
                                                if (currentHistorySlide !== index) {
                                                    slickSlider.slickGoTo(index);
                                                }
                                            }}
                                            type="button"
                                        >
                                            {item.year}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <SlickSlider
                            /* eslint-disable-next-line no-return-assign */
                            ref={(c) => (slickSlider = c)}
                            {...sliderSettings}
                            className={style.history__slider}
                        >
                            {data.history.map((item, index) => (
                                <div key={index} className={style.history__slide}>
                                    <h3 className="text-xl-mobile title-xs text-bold">
                                        {item.title}
                                    </h3>
                                    <TextEditorRender content={item._rawContent} />
                                </div>
                            ))}
                        </SlickSlider>
                    </div>
                </div>
            </div>
            {/* Values */}
            <div className={`${style.values} block-paddings wrapper-s`} id="03" ref={refValues}>
                <h2 className="title-sm-mobile title-lg text-semibold text-center">
                    {data.valuesTitle}
                </h2>
                {data._rawValuesContent && <TextEditorRender content={data._rawValuesContent} />}
            </div>
            {/* Youth */}
            {data._rawYouthContent &&
                <div className={`${style.academy} block-paddings wrapper-s`} id="04" ref={refYouth}>
                    <h2 className="title-sm-mobile title-lg text-semibold text-center">Ungdom</h2>
                    {data._rawYouthContent && <TextEditorRender content={data._rawYouthContent} />}
                </div>
            }
            {/* Academy */}
            {data._rawAcademyContent &&
                <div className={`${style.academy} block-paddings wrapper-s`} id="05" ref={refAcademy}>
                    <h2 className="title-sm-mobile title-lg text-semibold text-center">Akademi</h2>
                    {data._rawAcademyContent && <TextEditorRender content={data._rawAcademyContent} />}
                </div>
            }
            {/* Work for us */}
            <div className={`${style.work} block-paddings `} id="06" ref={refWork}>
                <div className="text-center wrapper-s">
                    <h2 className="title-sm-mobile title-lg text-semibold text-center">
                        {data.workForUsTitle}
                    </h2>
                    <p className="text-xl-mobile title-xs text-semibold text-darker">
                        {data.workForUsSubTitle}
                    </p>
                    <p className="text-md">{data.workForUs}</p>
                </div>
                <div className="wrapper">
                    <ul>
                        <li>
                            <ImageGatsby
                                {...data.workForUsLeftImage}
                                width={640}
                                height={325}
                                alt={data.workForUsLeftTitle}
                            />
                            <h3 className="text-xl-mobile title-xs text-semibold">
                                {data.workForUsLeftTitle}
                            </h3>
                            <p>{data.workForUsLeft}</p>
                            <a href={data.workForUsLeftLink} className="cta-yellow-ter" aria-label={getFormattedMessage('about.contact', lang)}>
                                <FormattedMessage id="about.contact" />
                            </a>
                        </li>
                        <li>
                            <ImageGatsby
                                {...data.workForUsRightImage}
                                width={640}
                                height={325}
                                alt={data.workForUsRightTitle}
                            />
                            <h3 className="text-xl-mobile title-xs text-semibold">
                                {data.workForUsRightTitle}
                            </h3>
                            <p>{data.workForUsRight}</p>
                            <a href={data.workForUsRightLink} className="cta-yellow-ter" aria-label={getFormattedMessage('about.contact', lang)}>
                                <FormattedMessage id="about.contact" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default About;
